import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { auth } from './firebase';
import './FlagBonusRound.css';
import ProgressInfo from './ProgressInfo';
import ShareButton from './ShareButton';

const FlagBonusRound = ({ sea, onNextChallenge, onCorrectGuess, onGuessUpdate, shareScore }) => {
  const [flagOptions, setFlagOptions] = useState([]);
  const [guessHistory, setGuessHistory] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [guessesRemaining, setGuessesRemaining] = useState(3);

  const memoizedSea = useMemo(() => ({
    id: sea?.iso_a2,
    name: sea?.name
  }), [sea?.iso_a2, sea?.name]);

  const getButtonClass = useCallback((countryCode) => {
    const guess = guessHistory.find(g => g.countryCode === countryCode);
    if (!guess) return '';
    return guess.isCorrect ? 'correct' : 'incorrect';
  }, [guessHistory]);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const token = await auth.currentUser?.getIdToken();
        const currentDate = format(new Date(), 'yyyy-MM-dd');
        
        const gameStateResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/gameState/fetch/${auth.currentUser?.uid}/${currentDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (!isMounted) return;

        if (gameStateResponse.data.gameState?.bonusRound?.flagGuesses) {
          const guesses = gameStateResponse.data.gameState.bonusRound.flagGuesses;
          setGuessHistory(guesses.map(guess => ({
            countryCode: guess.selectedCountry,
            isCorrect: guess.isCorrect
          })));
          setGuessesRemaining(3 - guesses.length);
          if (guesses.some(guess => guess.isCorrect) || guesses.length >= 3) {
            setShowAnswer(true);
          }
        }

        const flagResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/flag-bonus-round/${currentDate}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (!isMounted) return;

        setFlagOptions(flagResponse.data.options);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);


  const handleGuess = useCallback(async (countryCode) => {
    if (guessHistory.length >= 3 || guessHistory.some(guess => guess.isCorrect)) return;
    
    try {
      const token = await auth.currentUser?.getIdToken();
      const currentDate = format(new Date(), 'yyyy-MM-dd');
      
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/flag-bonus-round/${currentDate}`,
        { selectedCountry: countryCode },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const newGuess = {
        countryCode,
        isCorrect: response.data.isCorrect
      };
  
      setGuessHistory(prev => [...prev, newGuess]);
      const remainingGuesses = guessesRemaining - 1;
      setGuessesRemaining(remainingGuesses);

      if (response.data.isCorrect) {
        onCorrectGuess?.();
      }
  
      if (response.data.isCorrect || remainingGuesses === 0) {
        setShowAnswer(true);
      }
    } catch (error) {
      console.error('Error saving flag guess:', error);
    }
  }, [guessHistory, guessesRemaining, memoizedSea.id]);
  

  if (isLoading) {
    return <div className="flag-bonus-round-loading">Loading...</div>;
  }

  return (
    <div className="flag-bonus-round-container">
      <div className="flag-bonus-round-header">
        
        <h2>Which flag belongs to {memoizedSea.name}?</h2>
        {!showAnswer && (
          <div className="guesses-remaining">
            Guesses remaining: {guessesRemaining}
          </div>
        )}
      </div>

      <div className="flag-options">
        {flagOptions.map((option) => {
          const isGuessed = guessHistory.some(g => g.countryCode === option.iso_a2);
          const isCorrect = guessHistory.some(g => g.countryCode === option.iso_a2 && g.isCorrect);
          const isIncorrectGuess = guessHistory.some(g => g.countryCode === option.iso_a2 && !g.isCorrect);
          const wrapperClassName = `flag-option-wrapper ${
            isGuessed ? (isCorrect ? 'correct' : 'incorrect') : ''
          }`;

          return (
            <div key={option.iso_a2} className={wrapperClassName}>
              <button
                className={`flag-option ${getButtonClass(option.iso_a2)}`}
                onClick={() => !showAnswer && handleGuess(option.iso_a2)}
                disabled={showAnswer || isGuessed}
                data-country={option.iso_a2}
              >
                <img 
                  src={`${process.env.REACT_APP_API_URL}/api/flags/${option.iso_a2}-128.png`}
                  alt={`Flag of ${option.name}`}
                  className="flag-image"
                />
              </button>
              {showAnswer && (
                <div className={`flag-country-name ${option.iso_a2 === memoizedSea.id ? 'correct-answer' : ''} ${isIncorrectGuess ? 'incorrect-guess' : ''}`}>
                  {option.name}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {showAnswer && (
        <div className="flag-result">
          <div className="result-text">
            {guessHistory.some(guess => guess.isCorrect) ? (
              <span className="correct-text">Correct!</span>
            ) : (
              <span className="incorrect-text">Incorrect</span>
            )}
          </div>
          <div className="next-challenge-button" onClick={onNextChallenge}>
              <ProgressInfo 
                isCorrect={true}
                customText="💰 Next Challenge: Currency Quiz"
              />
            </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(FlagBonusRound);