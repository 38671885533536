import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobeProvider } from './GlobeContext';
import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { auth } from './firebase';
import axios from 'axios';
import { format } from 'date-fns';
import Header from './Header';
import GuessList from './GuessList';
import HowToPlayPopup from './HowToPlayPopup';
import AccountPopup from './AccountPopup';
import StatisticsPopup from './StatisticsPopup';
import SettingsPopup from './SettingsPopup';
import FAQ from './FAQ';
import { useTheme } from './ThemeContext';
import BonusRound from './BonusRound';
import FlagBonusRound from './FlagBonusRound';
import BonusWrapper from './BonusWrapper';

import './App.css';

const Globe = lazy(() => import('./components/globe/Globe'));

const App = () => {
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sea, setSea] = useState(null);
  const [proximity, setProximity] = useState(null);
  const [direction, setDirection] = useState(null);
  const [guesses, setGuesses] = useState([]);
  const [gameComplete, setGameComplete] = useState(false);
  const [guessCount, setGuessCount] = useState(0);
  const [isWon, setisWon] = useState(false);
  const [showHowToPlay, setShowHowToPlay] = useState(false);
  const [showAccountPopup, setShowAccountPopup] = useState(false);
  const [showStatisticsPopup, setShowStatisticsPopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
  const [nextPuzzleTime, setNextPuzzleTime] = useState(null);
  const [bonusRoundState, setBonusRoundState] = useState({
    isActive: false,
    currentRound: null // null, 'population', or 'flag'
  });

  const { theme } = useTheme();
  const apiUrl = process.env.REACT_APP_API_URL;

  const isPopupOpen = showHowToPlay || showAccountPopup || showStatisticsPopup || showSettingsPopup;

  const calculateTimeUntilNextPuzzle = () => {
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const timeUntil = tomorrow - now;
    return timeUntil;
  };

  const updateCountdown = () => {
    const timeUntil = calculateTimeUntilNextPuzzle();
    const hours = Math.floor(timeUntil / (1000 * 60 * 60));
    const minutes = Math.floor((timeUntil % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeUntil % (1000 * 60)) / 1000);
    setNextPuzzleTime(`${hours}h ${minutes}m ${seconds}s`);
  };

  useEffect(() => {
    localStorage.removeItem('bonusRoundShareScore');
    localStorage.setItem('bonusRoundShareScore', JSON.stringify({}));

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idToken = await user.getIdToken();
        setToken(idToken);
      } else {
        signInAnonymously(auth)
          .then(async () => {
            const user = auth.currentUser;
            if (user) {
              const idToken = await user.getIdToken();
              setToken(idToken);
            }
          })
          .catch((error) => {
            console.error('Error signing in anonymously:', error);
            setLoading(false);
          });
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (token) {
      fetchSeaData(token);
      fetchGameState(token);
    }
  }, [token]);

    // Save game state to localStorage whenever it changes
    useEffect(() => {
      if (guesses.length > 0) {
        // Just save to localStorage without triggering win/loss messages
        const gameState = {
          guesses,
          isWon,
          gameComplete,
          proximity,
          direction
        };
        localStorage.setItem('gameState', JSON.stringify(gameState));
      }
    }, [guesses, isWon, gameComplete, proximity, direction]);
  

  useEffect(() => {
    const isFirstTime = localStorage.getItem('firstTimeUser') === null;
    if (isFirstTime) {
      setShowHowToPlay(true);
      setIsFirstTimeUser(true);
      localStorage.setItem('firstTimeUser', 'no');
    }
  }, []);

  const fetchSeaData = (token) => {
    axios.get(`${apiUrl}/country/${format(new Date(), 'yyyy-MM-dd')}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(response => {
      console.log('Sea data:', response.data);
      setSea(response.data);
    })
    .catch(error => {
      console.error('Error fetching sea data:', error);
      if (error.response) {
        console.error('Error response data:', error.response.data);
      }
    });
  };
  

  const fetchGameState = (token) => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const currentDate = format(new Date(), 'yyyy-MM-dd');
      axios.get(`${apiUrl}/gameState/fetch/${userId}/${currentDate}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        if (response.data.gameState) {
          const { guesses, gameComplete, proximity, direction, win, date } = response.data.gameState;
          // Only set the state if it's from today's game
          if (date === currentDate) {
            setGuesses(guesses || []);
            setGameComplete(gameComplete || false);
            setProximity(proximity);
            setDirection(direction);
            setGuessCount(guesses ? guesses.length : 0);
            setisWon(win);
          } else {
            // Clear the state for a new day
            setGuesses([]);
            setGameComplete(false);
            setProximity(null);
            setDirection(null);
            setGuessCount(0);
            setisWon(false);
          }
        }
      })
      .catch(console.error);
    }
  };

  // Handle game completion notifications - separate from state saving
  const [hasShownGameCompleteToast, setHasShownGameCompleteToast] = useState(false);

  useEffect(() => {
    if (gameComplete && !hasShownGameCompleteToast) {
      if (isWon) {
        toast.success("Congratulations! You've won the game!");
        setGuesses((prevGuesses) => prevGuesses.filter(guess => !guess.isGhost));
      } else {
        toast.error("Game over! Better luck next time.");
      }
      setHasShownGameCompleteToast(true);
    } else if (!gameComplete) {
      // Reset the flag when game is not complete
      setHasShownGameCompleteToast(false);
    }
  }, [gameComplete, isWon, hasShownGameCompleteToast]);

  useEffect(() => {
    if (gameComplete) {
      updateCountdown();
      const timer = setInterval(updateCountdown, 1000);
      return () => clearInterval(timer);
    }
  }, [gameComplete]);

  const handleHowToPlayClose = () => {
    setShowHowToPlay(false);
    setIsFirstTimeUser(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <GlobeProvider>
      <Router>
        <div className={`app-container ${theme}`}>
          <Suspense fallback={<div>.</div>}>
          <div className="main-container">
          <Header 
            onAccountClick={() => setShowAccountPopup(true)} 
            onHowToPlayClick={() => setShowHowToPlay(true)} 
            onStatisticsClick={() => setShowStatisticsPopup(true)} 
            onSettingsClick={() => setShowSettingsPopup(true)} 
          />
          {!bonusRoundState.isActive ? (
            <>
              <Globe
                token={token}
                sea={sea}
                setSea={setSea}
                proximity={proximity}
                setProximity={setProximity}
                direction={direction}
                setDirection={setDirection}
                guesses={guesses}
                setGuesses={setGuesses}
                gameComplete={gameComplete}
                setGameComplete={setGameComplete}
                guessCount={guessCount}
                setGuessCount={setGuessCount}
                isWon={isWon}
                setisWon={setisWon}
                isPopupOpen={isPopupOpen}
              />
              <GuessList 
                sea={sea} 
                guesses={guesses} 
                gameComplete={gameComplete} 
                isWon={isWon}
                nextPuzzleTime={nextPuzzleTime}
                onBonusRound={() => {
                  setBonusRoundState({
                    isActive: true,
                    currentRound: 'population'
                  });
                }}
              />
            </>
          ) : (
            <BonusWrapper
              sea={{
                ...sea,
                iso_a2: sea?.iso_a2,
                name: sea?.name
              }}
              onReturn={() => {
                setBonusRoundState({
                  isActive: false,
                  currentRound: null
                });
              }}
            />
          )
        }
          <ToastContainer position="top-center" />
              {showHowToPlay && <HowToPlayPopup onClose={handleHowToPlayClose} isFirstTime={isFirstTimeUser} />}
              {showAccountPopup && <AccountPopup onClose={() => setShowAccountPopup(false)} />}
              {showStatisticsPopup && <StatisticsPopup onClose={() => setShowStatisticsPopup(false)} />}
              {showSettingsPopup && <SettingsPopup onClose={() => setShowSettingsPopup(false)} />}
              <div className="donation-link">
                <span style={{ fontSize: '20px' }}>❤️</span>
                <strong> Love SEALE? - </strong>
                <a href="https://buymeacoffee.com/seale" target="_blank" rel="noopener noreferrer">
                  Fund development!
                </a>
              </div>
              <div className="faq-container">
                <FAQ />
              </div>
            </div>
          </Suspense>
        </div>
      </Router>
    </GlobeProvider>
  );
};

export default App;
