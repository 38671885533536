import React, { useState, useEffect } from 'react';
import confetti from 'canvas-confetti';
import BonusRoundNav from './BonusRoundNav';
import BonusRound from './BonusRound';
import FlagBonusRound from './FlagBonusRound';
import CurrencyBonusRound from './CurrencyBonusRound';

const BonusWrapper = ({ sea, onReturn }) => {
  const [currentRound, setCurrentRound] = useState('population');
  const [shareScore, setShareScore] = useState({});

  useEffect(() => {
    // Mark the current round as played when it changes
    if (currentRound === 'population' || currentRound === 'flag' || currentRound === 'currency') {
      markRoundAsPlayed(currentRound);
    }
  }, [currentRound]);

  const handleNavigate = (roundId) => {
    if (roundId === 'globe') {
      onReturn();
    } else {
      setCurrentRound(roundId);
    }
  };

  useEffect(() => {
    // First, clear any existing shareScore to start fresh
    localStorage.removeItem('bonusRoundShareScore');
    
    // Initialize with an empty object
    localStorage.setItem('bonusRoundShareScore', JSON.stringify({}));
    setShareScore({});
  }, []);

  // Record which rounds have been attempted
  const markRoundAsPlayed = (roundType) => {
    setShareScore(prevScore => {
      // Get existing localStorage data first
      let existingData = {};
      try {
        const savedData = localStorage.getItem('bonusRoundShareScore');
        if (savedData) {
          existingData = JSON.parse(savedData);
        }
      } catch (error) {
        console.error('Error reading saved share score:', error);
      }

      // Create the new score - important to *only* include rounds that were played
      const newScore = { ...existingData };
      
      // Add this round to the played rounds (default to false until correct)
      if (!newScore.hasOwnProperty(roundType)) {
        newScore[roundType] = false;
      }
      
      localStorage.setItem('bonusRoundShareScore', JSON.stringify(newScore));
      return newScore;
    });
  };

  // Track flag guesses for sharing
  const updateFlagGuesses = (guessHistory) => {
    setShareScore(prevScore => {
      const newScore = { 
        ...prevScore, 
        flagGuessHistory: guessHistory 
      };
      localStorage.setItem('bonusRoundShareScore', JSON.stringify(newScore));
      return newScore;
    });
  };

  const updateShareScore = (roundType, isCorrect) => {
    setShareScore(prevScore => {
      const newScore = { ...prevScore, [roundType]: isCorrect };
      localStorage.setItem('bonusRoundShareScore', JSON.stringify(newScore));
      return newScore;
    });

    // Also check if we already have a stored gameState for today
    try {
      const savedGameState = localStorage.getItem('gameState');
      if (!savedGameState) {
        // If no game state exists, initialize with empty values
        // This ensures we can still show bonus results even if main game wasn't played
        const emptyGameState = {
          guesses: [],
          isWon: false,
          gameComplete: false,
          proximity: 0,
          direction: 0
        };
        localStorage.setItem('gameState', JSON.stringify(emptyGameState));
      }
    } catch (error) {
      console.error('Error checking game state:', error);
    }
  };

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };

  const renderBonusRound = () => {
    switch (currentRound) {
      case 'population':
        return (
          <BonusRound
            sea={sea}
            onReturn={onReturn}
            onNextChallenge={() => handleNavigate('flag')}
            onCorrectGuess={() => {
              triggerConfetti();
              updateShareScore('population', true);
            }}
            shareScore={shareScore}
          />
        );
      case 'flag':
        return (
          <FlagBonusRound
            sea={sea}
            onReturn={onReturn}
            onNextChallenge={() => handleNavigate('currency')}
            onCorrectGuess={() => {
              triggerConfetti();
              updateShareScore('flag', true);
            }}
            onGuessUpdate={updateFlagGuesses}
            shareScore={shareScore}
          />
        );
      case 'currency':
        return (
          <CurrencyBonusRound
            sea={sea}
            onReturn={onReturn}
            onNextChallenge={() => handleNavigate('globe')}
            onCorrectGuess={() => {
              triggerConfetti();
              updateShareScore('currency', true);
            }}
            shareScore={shareScore}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-slate-900">
      <div className="flex flex-col">
        <div className="w-full max-w-md mx-auto pt-4 px-4">
          <BonusRoundNav 
            currentRound={currentRound} 
            onNavigate={handleNavigate}
          />
        </div>

        <div>
          {renderBonusRound()}
        </div>
      </div>
    </div>
  );
};

export default BonusWrapper;