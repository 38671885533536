// src/ShareButton.js
import React, { useState } from 'react';
import { FaShareAlt } from 'react-icons/fa';

const ShareButton = ({ guesses, isWon, gameComplete, shareScore, isBonusRound = false }) => {
  const [isHovered, setIsHovered] = useState(false);

  const hasShareableContent = () => {
    // Check if we have something to share
    if (isBonusRound) {
      // In bonus mode, check if we have shareScore with at least one property
      return shareScore && Object.keys(shareScore).length > 0;
    } else {
      // In main mode, check if we have guesses
      return guesses && guesses.length > 0;
    }
  };

  // If there's nothing to share, don't render the button
  if (!hasShareableContent()) {
    return null;
  }

  const shareMainResults = (customGuesses, customIsWon) => {
    // Use either passed guesses or component props
    const usedGuesses = customGuesses || guesses;
    const usedIsWon = customIsWon !== undefined ? customIsWon : isWon;
    // Share main game results
    const startDate = new Date(2024, 4, 23); // May 21, 2024
    const today = new Date();
    const diffTime = Math.abs(today - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    const dateStr = today.toISOString().split('T')[0];
    const dateFormatted = `${dateStr.slice(8, 10)}.${dateStr.slice(5, 7)}.${dateStr.slice(0, 4)}`;

    const gameNumber = `#${diffDays}`;
    const guessesCount = usedIsWon ? `${usedGuesses.length}/3` : `X/3`;
    const closestProximity = usedGuesses && usedGuesses.length > 0 ? 
      `${Math.max(...usedGuesses.map(guess => guess.proximity))}%` : 
      `0%`;
    
    // Safety check - make sure usedGuesses is an array
    const safeGuesses = usedGuesses || [];
    
    const guessRows = safeGuesses.map(guess => {
      const proximity = guess.proximity;
      const direction = guess.direction;

      let emojiRow = '';
      if (proximity === 100) {
        emojiRow = '🟩🟩🟩🟩🟩✅';
      }
        else if (proximity >= 90) {
        emojiRow = '🟩🟩🟩🟩🟩';
      } else if (proximity >= 80) {
        emojiRow = '🟩🟩🟩🟩⬛';
      } else if (proximity >= 60) {
        emojiRow = '🟩🟩🟩⬛⬛';
      } else if (proximity >= 40) {
        emojiRow = '🟩🟩⬛⬛⬛';
      } else if (proximity >= 30) {
        emojiRow = '🟩⬛⬛⬛⬛';
      }
      else {
        emojiRow = '⬛⬛⬛⬛⬛';
      }

      let directionArrow = '';
      if (proximity !== 100) {
        if (direction === 0) {
          directionArrow = '➡️';
        } else if (direction > 0 && direction <= 45) {
          directionArrow = '↘️';
        } else if (direction > 45 && direction <= 135) {
          directionArrow = '⬇️';
        } else if (direction > 135 && direction <= 180) {
          directionArrow = '⬅️';
        } else if (direction > 180 && direction <= 225) {
          directionArrow = '↖️';
        } else if (direction > 225 && direction <= 315) {
          directionArrow = '⬆️';
        } else {
          directionArrow = '↗️';
        }
      }

      return `${emojiRow}${directionArrow}`;
    }).join('\n');

    return `${gameNumber} Seale ${dateFormatted} ${guessesCount} (${closestProximity})\n${guessRows}`;
  };

  const shareBonusResults = (scoreData) => {
    // Share bonus round results
    const startDate = new Date(2024, 4, 23); // May 23, 2024
    const today = new Date();
    const diffTime = Math.abs(today - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    const dateStr = today.toISOString().split('T')[0];
    const dateFormatted = `${dateStr.slice(8, 10)}.${dateStr.slice(5, 7)}.${dateStr.slice(0, 4)}`;

    const gameNumber = `#${diffDays}`;
    
    // Use either passed scoreData or the component's shareScore prop
    const scoreToUse = scoreData || shareScore || { population: false, flag: false, currency: false };
    
    // Track which rounds have been played (undefined means not played)
    // Check if the property exists to determine if it's been played
    const completedRounds = {
      population: scoreToUse.hasOwnProperty('population') ? scoreToUse.population : undefined,
      flag: scoreToUse.hasOwnProperty('flag') ? scoreToUse.flag : undefined,
      currency: scoreToUse.hasOwnProperty('currency') ? scoreToUse.currency : undefined
    };
    
    // For flag mode, check if there's guess history
    const flagGuessHistory = scoreToUse.flagGuessHistory || [];
    
    // Generate bonus round lines
    let resultLines = [];
    
    // Count correct answers and collect played rounds
    let attemptedCount = 0;
    let correctCount = 0;
    let playedRounds = [];
    
    // Only consider rounds that have the property (were played)
    if (scoreToUse.hasOwnProperty('population')) {
      attemptedCount++;
      if (scoreToUse.population) correctCount++;
      playedRounds.push({
        type: 'population',
        emoji: '👥',
        isCorrect: scoreToUse.population
      });
    }
    
    if (scoreToUse.hasOwnProperty('flag')) {
      attemptedCount++;
      if (scoreToUse.flag) correctCount++;
      playedRounds.push({
        type: 'flag',
        emoji: '🚩',
        isCorrect: scoreToUse.flag,
        guessHistory: flagGuessHistory
      });
    }
    
    if (scoreToUse.hasOwnProperty('currency')) {
      attemptedCount++;
      if (scoreToUse.currency) correctCount++;
      playedRounds.push({
        type: 'currency',
        emoji: '💰',
        isCorrect: scoreToUse.currency
      });
    }
    
    // Only add results if at least one round was attempted
    if (attemptedCount > 0) {
      resultLines.push(`Bonus Rounds`);
      
      // Show debugging info in development
      console.log('Bonus round share data:', JSON.stringify(scoreToUse));
      
      // Population round
      const hasPopulation = scoreToUse.hasOwnProperty('population');
      if (hasPopulation) {
        const populationEmoji = scoreToUse.population ? '🟩' : '⬛';
        resultLines.push(`👥 ${populationEmoji}`);
      } else {
        resultLines.push(`👥`);
      }
      
      // Flag round
      const hasFlag = scoreToUse.hasOwnProperty('flag');
      if (hasFlag) {
        if (flagGuessHistory && flagGuessHistory.length > 0) {
          let flagEmojis = '';
          flagGuessHistory.forEach(guess => {
            flagEmojis += guess.isCorrect ? '🟩' : '🟥';
          });
          for (let i = flagGuessHistory.length; i < 3; i++) {
            flagEmojis += '⬜';
          }
          resultLines.push(`🚩 ${flagEmojis}`);
        } else {
          const flagEmoji = scoreToUse.flag ? '🟩' : '⬛';
          resultLines.push(`🚩 ${flagEmoji}`);
        }
      } else {
        resultLines.push(`🚩`);
      }
      
      // Currency round
      const hasCurrency = scoreToUse.hasOwnProperty('currency');
      console.log('Has currency property:', hasCurrency);
      if (hasCurrency) {
        const currencyEmoji = scoreToUse.currency ? '🟩' : '⬛';
        resultLines.push(`💰 ${currencyEmoji}`);
      } else {
        resultLines.push(`💰`);
      }
    } else {
      // No bonus rounds attempted
      return "";
    }
    
    return resultLines.join('\n');
  };

  const shareResults = () => {
    // Always try to combine main game and bonus round results, regardless of mode
    let mainText = '';
    let bonusText = '';
    let shareText = '';
    
    // Get main game results if available
    if (!isBonusRound && guesses && guesses.length > 0) {
      // In main mode with guesses, use current props
      mainText = shareMainResults();
    } else if (isBonusRound) {
      // In bonus mode, try to fetch main game results from localStorage
      try {
        const savedGameState = localStorage.getItem('gameState');
        if (savedGameState) {
          const gameState = JSON.parse(savedGameState);
          if (gameState.guesses && gameState.guesses.length > 0) {
            // Create mock props for main game results
            const mockGuesses = gameState.guesses.map(guess => ({
              ...guess,
              // Ensure these properties exist
              proximity: guess.proximity || 0,
              direction: guess.direction !== undefined ? guess.direction : 0
            }));
            const mockIsWon = gameState.isWon || false;
            mainText = shareMainResults(mockGuesses, mockIsWon);
          }
        }
      } catch (error) {
        console.error('Error getting saved game state:', error);
      }
    }
    
    // Get bonus round results if available
    if (isBonusRound) {
      // In bonus mode, use current shareScore prop
      bonusText = shareBonusResults();
    } else {
      // In main mode, try to get from localStorage
      const savedBonusScore = localStorage.getItem('bonusRoundShareScore');
      if (savedBonusScore) {
        try {
          const bonusScore = JSON.parse(savedBonusScore);
          // Check if at least one round was played
          if (Object.keys(bonusScore).length > 0) {
            bonusText = shareBonusResults(bonusScore);
          }
        } catch (error) {
          console.error('Error parsing saved bonus score:', error);
        }
      }
    }
    
    // Combine results - ensure we have at least something to share
    if (!mainText && !bonusText) {
      // Nothing to share
      return;
    }
    
    // Combine main text and bonus text if both exist
    if (mainText && bonusText && bonusText.trim() !== "") {
      const mainParts = mainText.split('\n');
      const bonusParts = bonusText.split('\n');
      
      // First line from main text has the game number and date, keep it
      const header = mainParts[0];
      // Rest of main text (the proximity squares and arrows)
      const mainDetails = mainParts.slice(1).join('\n');
      
      // Skip the first line of bonus text (just use Bonus Rounds part)
      const bonusHeader = bonusParts[0].replace(/^.*Bonus Rounds/, 'Bonus Rounds');
      const bonusDetails = bonusParts.slice(1).join('\n');
      
      // Combine with a single header
      shareText = `${header}\n${mainDetails}\n\n${bonusHeader}\n${bonusDetails}`;
    } else if (mainText) {
      shareText = mainText;
    } else if (bonusText) {
      shareText = bonusText;
    }

    // Check if we're on mobile or desktop
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    if (navigator.share && isMobile) {
      // Use Web Share API on mobile devices
      navigator.share({
        title: 'Seale Game Results',
        text: shareText,
        url: 'https://seale.world/',
      })
      .then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      // On desktop, always use clipboard fallback
      navigator.clipboard.writeText(shareText)
        .then(() => {
          alert('Results copied to clipboard!');
        })
        .catch(err => {
          console.error('Could not copy text: ', err);
        });
    }
  };

  return (
    <button
      style={{
        ...styles.button,
        backgroundColor: isHovered ? '#222e49' : '#0f172a',
      }}
      onClick={shareResults}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FaShareAlt style={styles.icon} />
      {isBonusRound ? 'Share bonus results' : 'Share results'}
    </button>
  );
};

const styles = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px 20px',
    backgroundColor: '#1e293b',
    color: 'white',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'all 0.2s ease',
    minWidth: '160px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  icon: {
    marginRight: '10px',
    fontSize: '18px',
  },
};

export default ShareButton;
