import React, { useState, useEffect } from 'react';
import { FaTimes, FaQuestionCircle } from 'react-icons/fa';
import './HowToPlayPopup.css';
import ProgressInfo from './ProgressInfo';

const HowToPlayPopup = ({ onClose, isFirstTime }) => {
  const [showCloseButton, setShowCloseButton] = useState(!isFirstTime);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (isFirstTime) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setShowCloseButton(true);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isFirstTime]);

  const handleClose = () => {
    setShowCloseButton(true);
    onClose();
  };

  const exampleGuesses = [
    { proximity: 50, direction: 90 },
    { proximity: 75, direction: 45 }
  ];

  return (
    <div className="overlay">
      <div className="popup">
        <div className="popup-header">
          <h1><FaQuestionCircle className="icon" />Rules</h1>
          <div className="close-button-container">
            {showCloseButton ? (
              <button className="close-button" onClick={handleClose}>
                <FaTimes />
              </button>
            ) : (
              <span className="countdown">{countdown}</span>
            )}
          </div>
        </div>
        <div className="popup-content">
          <ul>
            <li><strong>You have 3 chances to guess the correct country.</strong></li>
            <li><strong>Each guess provides a proximity percentage.</strong></li>
            <ProgressInfo proximity={exampleGuesses[0].proximity} direction={exampleGuesses[0].direction} />
            <li><strong>An arrow shows the direction to the correct country.</strong></li>
            <ProgressInfo proximity={exampleGuesses[1].proximity} direction={exampleGuesses[1].direction} />
          </ul>
          <div className="example-guesses-container">
            <div className="example-guess">
            </div>
            <div className="example-guess">
              <p className="example-description">
                Good luck!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToPlayPopup;